import React from 'react'
// import Link from 'gatsby-link'
import styled from 'styled-components'
// import tigwLogo from '../images/tigw.png'
import ApplePodcasts from '../images/buttons/Apple-Podcasts.png'
import GooglePodcasts from '../images/buttons/Google-Podcasts.png'
import SpotifyPodcasts from '../images/buttons/Spotify-Podcasts.png'
import OvercastPodcasts from '../images/buttons/Overcast-Podcasts.png'
// import PocketcastsPodcasts from '../images/buttons/pocketcasts-podcast.png'


const RssLink = styled.div`
  width: fit-content;
  margin: auto;

  p {
    font-size: 1rem;
    position: relative;
    margin-left: 2.5rem;

    &::before {
      content: 'RSS: ';
      position: absolute;
      top: 0;
      left: -2.5rem;
    }
  }
`

const HomeHero = ({ siteTitle, data }) => (
<section className="hero home-hero">
  <div className="row">
    <div className="home-hero__text">
      <h1>THINGS I <br/>GOT <br/>WRONG <br/>
        <span className="home-hero__text--banner">AT TRIVIA</span>
      </h1>
    </div>
    <div className="subscribe">
      <a href="https://podcasts.apple.com/us/podcast/things-i-got-wrong-at-trivia-a-pub-quiz-game-show-with-friends/id1436232415">
        <img
          src={ApplePodcasts}
          alt="Subcribe on Apple Podcasts and iTunes"
          className="subscribe--button"
        />
      </a>

      <a href="https://open.spotify.com/show/2XqnAThGWli87TfNhNTyKL"> 
        <img
          src={SpotifyPodcasts}
          alt="Listen on Spotify"
          className="subscribe--button"
        />
      </a>

      <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy82OThlZTA0L3BvZGNhc3QvcnNz?sa=X&ved=2ahUKEwiIoprttK3sAhVbXs0KHcXGDL8Q9sEGegQIARAC">
        <img
          src={GooglePodcasts}
          alt="Subcribe on Google Podcasts for Android"
          className="subscribe--button"
        />
      </a>

      <a href="https://overcast.fm/itunes1436232415/things-i-got-wrong-at-trivia-a-pub-quiz-game-show-with-friends">
        <img
          src={OvercastPodcasts}
          alt="Subcribe on Overcast"
          className="subscribe--button"
        />
      </a>

      {/* <a href="https://pca.st/8ujk50jk">
        <img
          src={PocketcastsPodcasts}
          alt="Subcribe on Pocket Casts"
          className="subscribe--button"
        />
      </a> */}
    </div>
    <RssLink>
      <p>https://pcr.apple.com/id1436232415</p>
    </RssLink>
  </div>
</section>
)

export default HomeHero
