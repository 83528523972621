import React from 'react'

import ryanAvatar from '../images/ryan_400.jpg'
import rachelAvatar from '../images/rachel_400.jpg'
import stuAvatar from '../images/stu_400.jpg'

const AboutSection = () => (

<section id="about" className="home-about">
  <div className="row">
    <div className="contentArea">
      <h2>Your favorite general trivia podcast with a healthy dose of internet and media things.</h2>
      <div className="people">
        <div className="person">
          <img
            src={ryanAvatar}
            alt="headshot of Ryan Bott"
            className="avatar"
          />

          <div className="person--text">
            <h3>Ryan Bott</h3>
            <p>
              Music Maker. Dreamer of Dreams. Loves trivia nights, cooking, board games and crunching numbers in his head. Has been second guessing trivia answers since he was seven..
            </p> 
            <p>
              <strong>Pros:</strong> Math, Puzzles, Sports, Music<br/>
              <strong>Cons:</strong> History, Science 
            </p>
          </div>
        </div>

        <div className="person">
          <img
            src={rachelAvatar}
            alt="Headshot of Rachel Miller"
            className="avatar"
          />

          <div className="person--text">
            <h3>Rachel Miller</h3>
            <p>
              Lover of French and Netflix Binging. Avid Traveler and Fried Chicken Eater. Is actually a French speaking Tolkien elf. If confronted with solving a simple math problem or dying, she would die.
            </p>
            <p>
              <strong>Pros:</strong> World History, Language, Musicals, Literature<br/>
              <strong>Cons:</strong> Math, Puzzles
            </p>
          </div>
        </div>

        <div className="person">
          <img
            src={stuAvatar}
            alt="Headshot of Stu Hopkins"
            className="avatar"
          />

          <div className="person--text">
            <h3>Stuart Hopkins</h3>
            <p>
              Woodworker and blacksmith. Loves everything Disney, Video games and Plants. Better at Geology than geography. Can’t find Singapore on a map. 
            </p>
            <p>
              <strong>Pros:</strong> Science, Disney, Video games, Botany<br/>
              <strong>Cons:</strong> Geography, History 
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
)
export default AboutSection
