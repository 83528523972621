import React from 'react'

import Layout from '../components/layout'
import HomeHero from '../components/homeHero'
import AboutSection from '../components/AboutSection'
// import SupportSection from '../components/Support'
// import ShopSection from '../components/ShopSection'
import LatestEpisodes from '../components/LatestEpisodes'
import Resources from '../components/Resources'
import ContactForm from '../components/contactForm'

const IndexPage = () => (
  <Layout>
    <HomeHero/>
    <LatestEpisodes/>
    <AboutSection/>
    <Resources/>
    <ContactForm/>
  </Layout>
)

export default IndexPage
