import React from "react";
import { navigateTo } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <section className="contact-section" id="contact">
        <div className="row container">
          <div className="contentArea">
            <h2>Contact the Team</h2>
            <p>If you have a pick of your own that you think listeners would enjoy or if you have a trivia topic idea, you can send them in below as well as reach out for any general inquries.</p>
          </div>
          <div className="contact-form">
            <div className="row">
              <form
              name="contact"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label for="ignore field">
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </p>
                <label for="name">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    onChange={this.handleChange} />
                </label>
                <label for="email">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    onChange={this.handleChange} />
                </label>
                <label for="message">
                  <textarea
                    type="text"
                    name="message"
                    placeholder="Recommendation? Comment?"
                    onChange={this.handleChange} />
                </label>
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
            <p className="email-link">For other general inquries, email us at <a href="mailto:thingsigotwrong@gmail.com">thingsigotwrong@gmail.com</a></p>
        </div>
      </section>
    );
  }
}
