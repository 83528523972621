import React from 'react'

const BookPicks = [
  {
    title: 'What If?: Serious Scientific Answers to Absurd Hypothetical Questions',
    author: 'Randall Monroe',
    host: 'Ryan',
    link: 'https://amzn.to/2oWrdJX',
    episode: '#12 - Geometric States'
  }, {
    title: 'Creativity, Inc - The Story of Pixar',
    author: 'Ed Catmull',
    host: 'Ryan',
    link: 'https://amzn.to/2oOWnTQ',
    episode: '#17 - Duck Faced Nightmares'
  },{
    title: 'The Sleeping King',
    author: 'Cindy Dees & Bill Flippin',
    host: 'Rachel',
    link: 'https://amzn.to/2InsYqs',
    episode: '#18 - Hipster Branded Seltzer Water'
  },{
    title: 'The Name of the Wind (The Kingkiller Chronicles)',
    author: 'Patrick Rothfuss',
    host: 'Rachel',
    link: 'https://amzn.to/32YRO82',
    episode: '#19 - Is a Hotdog a Shoe?'
  }, {
    title: 'The Tough Guide to Fantasyland: The Essential Guide to Fantasy Travel',
    author: 'Diana Wynne Jones',
    host: 'Rachel',
    link: 'https://amzn.to/2t2M6WC',
    episode: '#35 - The Year 2020 Won'
  }, {
    title: 'Steal Like an Artist',
    author: 'Austin Kleon',
    host: 'Ryan',
    link: 'https://www.amazon.com/Steal-Like-Artist-Things-Creative/dp/0761169253/ref=sr_1_1?keywords=steal+like+an+artist&qid=1580674834&sr=8-1',
    episode: '#37 - Schrödinger\'s Blue Eyes'
  }, {
    title: 'Making Your Case: The Art of Persuading Judges',
    author: 'Antonin Scalia',
    host: 'Rachel',
    link: 'https://www.amazon.com/Scalia-Garners-Making-Your-Case-ebook/dp/B002PEP4NW/ref=sr_1_1?crid=1J2F7WQPAQAWN&dchild=1&keywords=making+your+case+the+art+of+persuading+judges&qid=1586627359&sprefix=making+your+case%2Caps%2C293&sr=8-1',
    episode: '#40 - Come on, Make JAM!'
  }, {
    title: 'The Self-Sufficient Backyard',
    author: 'Ron and Johanna Melchiore',
    host: 'Stu',
    link: 'https://www.amazon.com/Self-Sufficient-Backyard-Ron/dp/1732557160/ref=sr_1_3?crid=DAMGI8ACUP4R&dchild=1&keywords=self+sufficient+backyard&qid=1597996573&sprefix=The+Self-Suficient+Backyard%2Caps%2C183&sr=8-3',
    episode: '#50 - Two Animal Bingos'
  }, {
    title: 'Calling Bullshit: The Art of Skepticism in a Data-Driven World',
    author: 'Carl Bergstrom and Jevin West',
    host: 'Ross',
    link: 'https://www.callingbullshit.org/',
    episode: '#60 - Spitecasting'
  }, {
    title: 'Cthulhu Mythos (DM Book Guide)',
    host: 'Nathan',
    link: 'https://petersengames.com/cthulhu-mythos/',
    episode: '#62 - Snow Globes in Your Mind'
  },
]

const ExperiencePicks = [
  {
    title: 'Get into Gardening',
    host: 'Stu',
    link: 'https://www.youtube.com/watch?v=nJdLVua5z8E',
    episode: '#20 - All the Wrong Answers at all the Right Times'
  },{
    title: 'Go Outside to Watch a Sunset',
    host: 'Stu',
    link: 'https://www.youtube.com/watch?v=jEnd8JIMii4',
    episode: '#26 - Ryan Fest 2019'
  }, {
    title: 'Visit an Escape Room',
    host: 'Ryan',
    link: 'https://theescapegame.com/',
    episode: '#39 - Turtles in a Half-Point'
  }, {
    title: 'Getting into Making jam',
    host: 'Stu',
    link: 'https://www.youtube.com/watch?v=KUGjgUA-BWU',
    episode: '#40 - Come on, Make JAM!'
  }, {
    title: 'Perler Bead Crafts',
    host: 'Mack',
    link: 'https://www.youtube.com/watch?v=Pc6W9Di4YEw',
    episode: '#53 - An Eye for an Ice Cream Cone'
  }, {
    title: 'Sensory Deprivation Tank',
    host: 'Stu',
    link: 'https://www.healthline.com/health/sensory-deprivation-tank',
    episode: '#66 - One Step Beyond the Obvious'
  }, {
    title: 'Keep your toesies warm by a fire',
    host: 'Rachel',
    link: '#',
    episode: '#67 - We Don\'t Do Arson'
  }, {
    title: 'Buying food from a local farm stand',
    host: 'Stu',
    link: '#',
    episode: '#76 - The Pumpkin Spice Ethereal Plane'
  },
]

const MoviePicks = [
  {
    title: 'Spider-Man: Into the Spider-Verse',
    host: 'Ryan',
    link: 'https://www.imdb.com/title/tt4633694/?ref_=fn_al_tt_1',
    episode: '#13 - Hakuna Matata means MORE worries'
  },
  {
    title: 'Howl\'s Moving Castle',
    host: 'Stu',
    link: 'https://www.imdb.com/title/tt0347149/',
    episode: '#22 - A Quarrel of Coral'
  }, {
    title: 'Lord of the Rings - Extended Edition',
    host: 'Rachel',
    link: 'https://amzn.to/2ZhDCoh',
    episode: '#26 - Ryan Fest 2019'
  }, {
    title: 'Magic Mike XXL',
    host: 'Julia',
    link: 'https://www.imdb.com/title/tt2268016/',
    episode: '#56 - Why Start Small When You Can Start XXL'
  }, {
    title: 'Ocean\'s Eight',
    host: 'Lauren',
    link: 'https://www.imdb.com/title/tt5164214/',
    episode: '#56 - Why Start Small When You Can Start XXL'
  }, {
    title: 'Spy',
    host: 'Rachel',
    link: 'https://www.imdb.com/title/tt3079380/',
    episode: '#56 - Why Start Small When You Can Start XXL'
  }, {
    title: 'Werewolves Within',
    host: 'Nathan',
    link: 'https://www.imdb.com/title/tt9288692/',
    episode: '#73 - Consonants and Confidence'
  }, 
]

const MusicPicks = [
  {
    title: 'Athlete - Beyond the Neighbourhood',
    host: 'Ryan',
    link: 'https://open.spotify.com/album/6EzVcsj1UH4hpfL14zOo4s?si=-UR8dCIFT56Ng82tsZUJkA',
    episode: '#25 - Three Courageous Lions and One Frumpy Half-Horse Man'
  }, {
    title: 'Alekesam - Sound Proof Heart',
    host: 'Ryan',
    link: 'https://alekesam.bandcamp.com/',
    episode: '#51 - Tougher than a Waffle House Steak'
  },
]

const TvPicks = [
  {
    title: 'Black Clover (anime)',
    host: 'Stu',
    link: 'https://www.imdb.com/title/tt7441658/?ref_=nv_sr_1?ref_=nv_sr_1',
    episode: '#14 - If You Can Picture it, You Can Vaguely Draw it'
  }, {
    title: 'The Story of Us (Netflix)',
    host: 'Rachel',
    link: 'https://www.imdb.com/title/tt7492116/',
    episode: '#29 - Date Night Goose Games'
  }, {
    title: 'My Hero Academia anime',
    host: 'Stu',
    link: 'https://www.imdb.com/title/tt5626028/',
    episode: '#34 - The Great Christmas Pickle'
  }, {
    title: 'Blown Away (Netflix)',
    host: 'Rachel',
    link: 'https://www.imdb.com/title/tt9908860/',
    episode: '#42 - Don\'t Anakin Your Arm Off'
  }, {
    title: 'The Last Dance - Michael Jordan Documentary (ESPN)',
    host: 'Ryan',
    link: 'https://www.espn.com/nba/story/_/id/28973557/the-last-dance-updates-untold-story-michael-jordan-chicago-bulls',
    episode: '#45 - Gandalf\'s Beard: The Musical'
  }, {
    title: 'How to Fix a Drug Scandal (Netflix)',
    host: 'Rachel',
    link: 'https://www.netflix.com/title/80233339',
    episode: '#47 - Space Points! (space points)'
  }, {
    title: 'The Speed Cubers (Competitive Rubik\'s Cube Documentary - Netflix)',
    host: 'Chris',
    link: 'https://www.netflix.com/title/81092143',
    episode: '#51 - Tougher than a Waffle House Steak'
  }, {
    title: 'One Day at Disney - Shorts (Disney+)',
    host: 'Ryan',
    link: 'https://www.imdb.com/title/tt10854760/episodes?season=1&ref_=tt_eps_sn_1',
    episode: '#61 - Farm Kid Tractor License'
  }, {
    title: 'Cells at Work!',
    host: 'Rachel',
    link: 'https://www.imdb.com/title/tt8673610/?ref_=nv_sr_srsg_0',
    episode: '#64 - More Trivia. More Fun. All Around Greatness.'
  }, {
    title: 'Ted Lasso',
    host: 'Ryan',
    link: 'https://www.imdb.com/title/tt10986410/?ref_=fn_al_tt_1',
    episode: '#64 - More Trivia. More Fun. All Around Greatness.'
  }, {
    title: 'Only Murders in the Building (Hulu original series)',
    host: 'Ryan',
    link: 'https://www.hulu.com/series/only-murders-in-the-building-ef31c7e1-cd0f-4e07-848d-1cbfedb50ddf',
    episode: '#76 - The Pumpkin Spice Ethereal Plane'
  }, {
    title: 'Made You Look: A True Story About Fake Art',
    host: 'Ryan',
    link: 'https://www.netflix.com/title/81406333',
    episode: '#77 - The Collective Brain Cell'
  },
]

const ProductPicks = [
  {
    title: 'Bose QC-35 Noise Cancelling Headphones',
    host: 'Ryan',
    link: 'https://amzn.to/2AJJ4qw',
    episode: '#19 - Is a Hotdog a Shoe?'
  }, {
    title: 'Russel+Hazel - Paper Stock and Notebooks',
    host: 'Rachel',
    link: 'https://www.russellandhazel.com/',
    episode: '#27 - How\'s it Feel to Be Me??'
  }, {
    title: 'OURA Air Mask',
    host: 'Stu',
    link: 'https://ouragami.org/products/oura-air-mask?variant=32337299931207',
    episode: '#45 - Gandalf\'s Beard: The Musical'
  }, {
    title: 'Rishi Ginger Turmeric Tea',
    host: 'Ryan',
    link: 'https://www.rishi-tea.com/product/turmeric-ginger-organic-caffeine-free-herbal-blend/loose-leaf-tea',
    episode: '#48 - Muscle, Muscle, Muscle, FLEX!'
  }, {
    title: 'Fermentation Food Lids',
    host: 'Ryan',
    link: 'https://www.amazon.com/Fermentation-Kit-Wide-Mouth-Jars/dp/B075LRMRDQ/ref=sr_1_6?crid=3LRWTKJ6RS962&dchild=1&keywords=fermentation+lids&qid=1597996513&sprefix=fermentation%2Caps%2C182&sr=8-6',
    episode: '#50 - Two Animal Bingos'
  }, {
    title: 'Autonomous Ai: Ergo 2 Office Chair + Roller Blade Office Wheels',
    host: 'Ryan',
    link: 'https://www.autonomous.ai/office-chairs/ergonomic-chair?option20=56',
    episode: '#52 - Magnum Opus'
  }, {
    title: 'Aseprite - Animated Sprite Editor & Pixel Art Tool',
    host: 'Stu',
    link: 'https://www.aseprite.org/',
    episode: '#53 - An Eye for an Ice Cream Cone'
  }, {
    title: 'Raspberry Pi (DIY Computer)',
    host: 'Lam',
    link: 'https://www.raspberrypi.org/',
    episode: '#53 - An Eye for an Ice Cream Cone'
  }, {
    title: 'Under the Sun Plant Seeds (Etsy)',
    host: 'Rachel',
    link: 'https://www.etsy.com/shop/UnderTheSunSeeds',
    episode: '#55 - Half Past the Canadian Woodpecker'
  }, {
    title: 'Cuckoo Rice Cooker',
    host: 'Stu',
    link: 'https://www.cuckoousastore.com/',
    episode: '#57 - Hostful Goodness'
  }, {
    title: 'Simplenote',
    host: 'Ryan',
    link: 'https://simplenote.com/',
    episode: '#57 - Hostful Goodness'
  }, {
    title: 'Samsung 34" SJ55W Ultra WQHD Monitor',
    host: 'Santa',
    link: 'https://www.samsung.com/us/computing/monitors/uhd-and-wqhd/34-sj55w-ultra-wqhd-monitor-ls34j550wqnxza/',
    episode: '#58 - Ho! Ho! Ho! ....No.'
  }, {
    title: 'Bliss Collections Essential Weekly Planner',
    host: 'Rachel',
    link: 'https://www.amazon.com/Bliss-Collections-Essential-Organizer-Productivity/dp/B087HNC58J/ref=redir_mobile_desktop?ie=UTF8&psc=1&ref=ppx_pop_mob_b_asin_title',
    episode: '#59 - That Line was Lit'
  }, {
    title: 'Malicious Women Candles',
    host: 'Rachel',
    link: 'https://maliciouswomenco.com/',
    episode: '#63 - Load Bearing Dictionaries'
  }, {
    title: 'Quaker Oats Instant Oatmeal with Dinosaur Eggs',
    host: 'Rachel',
    link: 'https://www.quakeroats.com/products/hot-cereals/instant-oatmeal/dinosaur-eggs',
    episode: '#65 - Fisticuffs & Meat Hooks'
  }, {
    title: 'Apple Watch',
    host: 'Ryan',
    link: 'https://www.apple.com/watch/',
    episode: '#66 - One Step Beyond the Obvious'
  }, {
    title: 'Free Flash Decompiler',
    host: 'Stu',
    link: 'https://github.com/jindrapetrik/jpexs-decompiler',
    episode: '#67 - We Don\'t Do Arson'
  }, {
    title: 'Signal - encrypted message app',
    host: 'Ryan',
    link: 'https://signal.org',
    episode: '#68 - The Great Tortilla Chip in the Sky'
  }, {
    title: 'Silicone Straws',
    host: 'Rachel',
    link: 'https://www.amazon.com/Senneny-Silicone-Straws-30oz-Tumblers/dp/B07PDBL96K/ref=sr_1_1?dchild=1&keywords=silicone+straws&qid=1623989945&sr=8-1',
    episode: '#69 - People = Depths of Hell'
  }, {
    title: 'Large White Board',
    host: 'Rachel',
    link: 'https://www.amazon.com/VIZ-PRO-Magnetic-Inches-Silver-Aluminium/dp/B00U398RDU/ref=sr_1_5?dchild=1&keywords=white+board&qid=1623990098&sr=8-5',
    episode: '#70 - We Have Engaged in Take-Backsies '
  }, {
    title: 'XP-Pen Artist 12 Drawing tablet',
    host: 'Stu',
    link: 'https://www.xp-pen.com/product/64.html',
    episode: '#70 - We Have Engaged in Take-Backsies '
  }, {
    title: 'Korean Skin Care Routine',
    host: 'Rachel',
    link: 'https://sokoglam.com/pages/the-korean-skin-care-routine',
    episode: '#75 - Bring a Jacket. Think Twice. Get Soaked in Rain.'
  }, {
    title: 'Gem Lights hair color',
    host: 'Rachel',
    link: 'https://www.amazon.com/Gem-Lites-Tourmaline-Colorwash-8-25oz/dp/B01JQWSF8G/ref=sr_1_5?crid=3CU57E98PLLGQ&dchild=1&keywords=gem+lites+colorwash+shampoo+tourmaline&qid=1634002690&sprefix=gem+lites+shampoo+tou%2Caps%2C200&sr=8-5',
    episode: '#77 - The Collective Brain Cell'
  }, {
    title: 'Blender Bottles',
    host: 'Rachel',
    link: 'https://www.blenderbottle.com/',
    episode: '#79 - Pony-to-Pony Networking'
  },
]

const PodcastPicks = [
  {
    title: 'Not Another D&D Podcast',
    host: 'Rachel',
    link: 'https://headgum.com/not-another-dandd-podcast',
    episode: '#15 - I\'m Not a Doctor, I\'m a Trivia Host'
  }, {
    title: 'The Dungeon Run',
    host: 'Ryan',
    link: 'https://www.caffeine.tv/thedungeonrun',
    episode: '#21 - Pizza Rat Dubstep'
  }, {
    title: 'Conan O\'Brien Needs a Friend - Dana Carvey Deep Dive',
    host: 'Ryan',
    link: 'https://www.earwolf.com/show/conan-obrien/',
    episode: '#27 - How\'s it Feel to Be Me??'
  }, {
    title: 'Sawbones - Medical Mysteries Podcast',
    host: 'Stu',
    link: 'https://www.maximumfun.org/shows/sawbones',
    episode: '#30 - Stuart of the Corn'
  }, {
    title: 'American Scandal - True Crime Podcast',
    host: 'Rachel',
    link: 'https://wondery.com/shows/american-scandal/',
    episode: '#31 - Shoot First, Ask Questions Later'
  }, {
    title: 'Opening Arguments Podcast',
    host: 'Rachel',
    link: 'https://openargs.com/',
    episode: '#33 - Two-Factor Monkey Auth'
  }, {
    title: 'Not Another D&D Podcast - ElderMourne',
    host: 'Sydney',
    link: 'https://www.naddpod.com/',
    episode: '#54 - A Normal Amount of Spiders'
  }, {
    title: 'We Have Concerns - Comedy Science Podcast',
    host: 'Ryan',
    link: 'https://podcasts.apple.com/us/podcast/we-have-concerns/id897623231',
    episode: '#80 - Connecticut Doesn\'t Exist'
  }, 
]

const GamePicks = [
  {
    title: 'Lords of Waterdeep Board Game',
    host: 'Rachel',
    link: 'https://amzn.to/35aK6cL',
    episode: '#11  - Your Average Bear'
  },{
    title: 'TowerFall Video Game (PC, Switch, PS4, XB1)',
    host: 'Stu',
    link: 'http://www.towerfall-game.com/',
    episode: '#17 - Duck Faced Nightmares'
  },{
    title: 'Dragon Age: Inquisition (PC, PS4, XB1)',
    host: 'Rachel',
    link: 'https://www.ea.com/games/dragon-age/dragon-age-inquisition',
    episode: '#22 - A Quarrel of Coral'
  },{
    title: 'Magic: Duels (Magic: The Gathering - iOS, PC, XB1)',
    host: 'Ryan',
    link: 'https://magic.wizards.com/en/content/magic-duels-download',
    episode: '#23 - Just Bury my Shame in the New Mexico Desert'
  },{
    title: 'Slay the Spire (PC, Switch)',
    host: 'Stu',
    link: 'https://www.megacrit.com/',
    episode: '#24 - Take That, Encyclopedia Britannica!'
  }, {
    title: 'Full Metal Furies - Retro, team-based co-op game (Switch, Steam, XB1)',
    host: 'Stu',
    link: 'http://cellardoorgames.com/our-games/full-metal-furies/',
    episode: '#28 - Podcast Pizza Party'
  }, {
    title: 'Apple Arcade (What the Golf, Grindstone, Pinball Wizard)',
    host: 'Ryan',
    link: 'https://www.apple.com/apple-arcade/',
    episode: '#30 - Stuart of the Corn'
  }, {
    title: 'Moonlighter (Switch, PS4, XB1, PC)',
    host: 'Stu',
    link: 'http://moonlighterthegame.com/',
    episode: '#32 - All that Devil Water'
  }, {
    title: '9 Parchments (Switch, PS4, XB1, PC)',
    host: 'Stu',
    link: 'https://www.nineparchments.com/',
    episode: '#41 - Corn Chip Hats for Your Hands'
  }, {
    title: 'Bug Fables (Switch, PS4, XB1, PC)',
    host: 'Stu',
    link: 'https://www.nintendo.com/games/detail/bug-fables-the-everlasting-sapling-switch/',
    episode: '#48 - Muscle, Muscle, Muscle, FLEX!'
  }, {
    title: 'Stranger Things: The Game (iOS, Android)',
    host: 'Ryan',
    link: 'https://apps.apple.com/gb/app/stranger-things-the-game/id1220479307 ',
    episode: '#54 - A Normal Amount of Spiders'
  }, {
    title: 'Dread - Story telling Horror Tabletop Game',
    host: 'JP',
    link: 'https://www.drivethrurpg.com/product/83854/Dread',
    episode: '#55 - Half Past the Canadian Woodpecker'
  }, {
    title: 'Demon Souls (PS3 Remake on PS5)',
    host: 'Nathan',
    link: 'https://www.playstation.com/en-us/games/demons-souls/',
    episode: '#58 - Ho! Ho! Ho! ....No.'
  }, {
    title: 'Captain Toad: Treasure Tracker',
    host: 'Stu',
    link: 'https://www.nintendo.com/games/detail/captain-toad-treasure-tracker-switch/',
    episode: '#60 - Spitecasting'
  }, {
    title: 'Sea of Thieves (PC, Xbox)',
    host: 'Ryan',
    link: 'https://www.seaofthieves.com/',
    episode: '#62 - Snow Globes in Your Mind'
  }, {
    title: 'Bowser\'s Fury (Switch)',
    host: 'Stu',
    link: 'https://supermario3dworld.nintendo.com/bowsers-fury/',
    episode: '#63 - Load Bearing Dictionaries'
  }, {
    title: 'Stardew Valley (Switch, PC, PS4, XB1)',
    host: 'Stu',
    link: 'https://www.stardewvalley.net/',
    episode: '#65 - Fisticuffs & Meat Hooks'
  }, {
    title: 'Dicey Dungeons (Switch, PC)',
    host: 'Stu',
    link: 'https://www.nintendo.com/games/detail/dicey-dungeons-switch/',
    episode: '#71 - The Trifecta of World Chaos'
  }, {
    title: 'ISLANDERS (Switch, PC)',
    host: 'Stu',
    link: 'https://www.nintendo.com/games/detail/islanders-switch/',
    episode: '#74 - Just Trying to Keep it on the Rails'
  }, {
    title: 'Unsighted (Switch, PC, Xbox, PS)',
    host: 'Stu',
    link: 'https://www.nintendo.com/games/detail/unsighted-switch/',
    episode: '#79 - Pony-to-Pony Networking'
  },
]

const WebsitePicks = [
  {
    title: 'Homestyler.com - 3D Home Designer',
    host: 'Rachel',
    link: 'https://www.homestyler.com/int/',
    episode: '#23 - Just Bury my Shame in the New Mexico Desert'
  }, {
    title: 'Couch to 5K running app',
    host: 'Ryan',
    link: 'https://www.c25kfree.com/',
    episode: '#32 - All that Devil Water'
  }, {
    title: 'AudioKit Synth One (iOS open source synth music app)',
    host: 'Ryan',
    link: 'https://audiokitpro.com/synth/',
    episode: '#34 - The Great Christmas Pickle'
  }, {
    title: 'Please donate to help support those impacted by the devastating Australian Fires',
    host: 'Stu',
    link: 'http://givit.org.au/',
    episode: '#35 - The Year 2020 Won'
  }, {
    title: 'Rejected Princesses - A celebration of women in history and myth',
    host: 'Rachel',
    link: 'https://www.rejectedprincesses.com/',
    episode: '#38 - The Expert Nephelococcygian'
  }, {
    title: 'Nebo Note Taking App (iPad, Android, Surface)',
    host: 'Rachel',
    link: 'https://www.nebo.app/',
    episode: '#46 - Mob Boss Fetch Quests'
  }, {
    title: 'Vanness - Family owned paper company',
    host: 'Rachel',
    link: 'https://vanness1938.com/pages/about-us',
    episode: '#52 - Magnum Opus'
  }, {
    title: ' Lore Olympus - Webcomic',
    host: 'Raylene',
    link: 'https://www.webtoons.com/en/romance/lore-olympus/list?title_no=1320&page=1',
    episode: '#54 - A Normal Amount of Spiders'
  }, {
    title: 'FreeCodeCamp.org and GirlsWhoCode.com',
    host: 'Ryan',
    link: 'https://www.freecodecamp.org/',
    episode: '#59 - That Line was Lit'
  }, {
    title: 'Instagram: Nick\'s Nature Shots',
    host: 'Stu',
    link: 'https://www.instagram.com/nicks_nature_shots/',
    episode: '#68 - The Great Tortilla Chip in the Sky'
  }, {
    title: 'Library Extension',
    host: 'Ryan',
    link: 'https://www.libraryextension.com/',
    episode: '#71 - The Trifecta of World Chaos'
  }, {
    title: 'Strangely Made Works',
    host: 'Sydney',
    link: 'https://www.etsy.com/shop/StrangelyMadeWorks',
    episode: '#72 - Yay! Train Wrecks'
  }, {
    title: 'Zenni Optical',
    host: 'Ryan',
    link: 'https://www.zennioptical.com/',
    episode: '#74 - Just Trying to Keep it on the Rails'
  }, 
]

const YoutubePicks = [
  {
    title: 'Kaptain Kristian',
    host: 'Stu',
    link: 'https://www.youtube.com/channel/UCuPgdqQKpq4T4zeqmTelnFg',
    episode: '#11  - Your Average Bear'
  }, {
    title: 'Viper Keeper',
    host: 'Rachel',
    link: 'https://www.youtube.com/user/viperkeeper',
    episode: '#13 - Hakuna Matata means MORE worries'
  }, {
    title: 'Summoning Salt (Speedrunning)',
    host: 'Stu',
    link: 'https://www.youtube.com/channel/UCtUbO6rBht0daVIOGML3c8w',
    episode: '#16 - Pure Uncut Colombian Latin'
  }, {
    title: 'Dry Bar Comedy',
    host: 'Ryan',
    link: 'https://www.youtube.com/channel/UCvlVuntLjdURVD3b3Hx7kxw',
    episode: '#36 - You Had Me at "Dabba Dee"'
  }, {
    title: 'NakeyJakey',
    host: 'Stu',
    link: 'https://www.youtube.com/channel/UCSdma21fnJzgmPodhC9SJ3g',
    episode: '#37 - Schrödinger\'s Blue Eyes'
  }, {
    title: '"Live at Home" Concerts (Ben Gibbard from Death Cab for Cutie)',
    host: 'Ryan',
    link: 'https://www.youtube.com/watch?v=2TiCThAAcNM',
    episode: '#41 - Corn Chip Hats for Your Hands'
  }, {
    title: 'Game Maker\'s Toolkit',
    host: 'Stu',
    link: 'https://www.youtube.com/user/McBacon1337',
    episode: '#44 - I Heard the Bell Ring'
  }, {
    title: 'Amanda Gordon\'s Inauguration Poem',
    host: 'Chris',
    link: 'https://www.youtube.com/watch?v=LZ055ilIiN4',
    episode: '#61 - Farm Kid Tractor License'
  }, {
    title: 'Epic Gardening',
    host: 'Ryan',
    link: 'https://www.youtube.com/user/EpicGardening',
    episode: '#69 - People = Depths of Hell'
  }, {
    title: 'North of the Border',
    host: 'Stu',
    link: 'https://www.youtube.com/channel/UCM15YNy8g-CaJ15YZCbq0Iw',
    episode: '#73 - Consonants and Confidence'
  }, {
    title: 'Sarah Millican comedian',
    host: 'Rachel',
    link: 'https://www.youtube.com/watch?v=th_nxlbXtTk',
    episode: '#80 - Connecticut Doesn\'t Exist'
  },
]

const Resources = ({ siteTitle, data }) => (

<section id="picks" className="home-picks">
  <div className="podcast-container">
    <div className="pick-section">
      <h2>Picks &amp; Recommendations</h2>
      <p>Each episode, a couple of us give a movie, game, experience or something cool that we recommend you checkout:</p>

    <div className="all-pick-categories">

      <div className="pick-category">
        <h3>Books</h3>
        <ul>
          {BookPicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title} by {link.author}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Games</h3>
        <ul>
          {GamePicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Experiences</h3>
        <ul>
          {ExperiencePicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Movies</h3>
        <ul>
          {MoviePicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Music</h3>
        <ul>
          {MusicPicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Podcasts</h3>
        <ul>
          {PodcastPicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Products</h3>
        <ul>
          {ProductPicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>TV</h3>
        <ul>
          {TvPicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Websites &amp; Apps</h3>
        <ul>
          {WebsitePicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="pick-category">
        <h3>Youtube</h3>
        <ul>
          {YoutubePicks.map(link => (
            <li key={link.title}>
              <p>{link.host}&#39;s pick from {link.episode} </p>
              <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      </div>

    </div>

    </div>
  </div>
</section>
)

export default Resources
